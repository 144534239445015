export const SHORTCUT_MENUS = [
  { icon: "i-Shop-4", link: "#", text: "Home" },
  { icon: "i-Library", link: "#", text: "Ui Kits" },
  { icon: "i-Drop", link: "#", text: "Apps" },
  { icon: "i-File-Clipboard-File--Text", link: "#", text: "Form" },
  { icon: "i-Checked-User", link: "#", text: "Sessions" },
  { icon: "i-Ambulance", link: "#", text: "Support" }
];

export const NOTIFICATIONS = [
  // {
  //   icon: "i-Speach-Bubble-6",
  //   title: "New message",
  //   description: "James: Hey! are you busy?",
  //   time: "2019-10-30T02:10:18.931Z",
  //   color: "primary",
  //   status: "New"
  // },
  // {
  //   icon: "i-Receipt-3",
  //   title: "New order received",
  //   description: "1 Headphone, 3 iPhone",
  //   time: "2019-03-10T02:10:18.931Z",
  //   color: "success",
  //   status: "New"
  // },
  // {
  //   icon: "i-Empty-Box",
  //   title: "Product out of stock",
  //   description: "1 Headphone, 3 iPhone",
  //   time: "2019-05-10T02:10:18.931Z",
  //   color: "danger",
  //   status: "3"
  // },
  // {
  //   icon: "i-Data-Power",
  //   title: "Server up!",
  //   description: "Server rebooted successfully",
  //   time: "2019-03-10T02:10:18.931Z",
  //   color: "success",
  //   status: "3"
  // }
];
