import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countryList: [],
  zipCodeList: []
};

const masterSlice = createSlice({
  name: "buildingSlice",
  initialState,
  reducers: {
    GetCountryListAction: (state, action) => {
      state.countryList = action.payload.countryList;
    },
    GetZipCodeListAction: (state, action) => {
      state.zipCodeList = action.payload.zipCodeList;
    },
  },
});

export const {
    GetCountryListAction,
    GetZipCodeListAction
} = masterSlice.actions;

export default masterSlice.reducer;
