import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export default function MegaMenu() {
  return (
    <Row>
      {/* <Col md={6} className="p-4 bg-img">
        <h2 className="title">EULA</h2>

        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores
          natus laboriosam fugit, consequatur.
        </p>

        <p className="mb-4">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          Exercitationem odio amet eos dolore suscipit placeat.
        </p>

        <button className="btn btn-lg btn-rounded btn-outline-warning">
          Learn More
        </button>
      </Col> */}

      <Col md={6} className="p-4">
        <p className="text-primary text--cap border-bottom-primary d-inline-block">
          Meny
        </p>

        <div className="menu-icon-grid w-auto p-0">
          <Link to="/">
            <i className="i-Shop-4"></i> Hjem
          </Link>

          <Link to="/userDashboard/accesspoint">
            <i className="i-Lock-2" style={{ whiteSpace: "nowrap" }}></i>{" "}
            Aksesspunkt
          </Link>

          <Link to="/userDashboard/accessgroups">
            <i className="i-Link-2" style={{ whiteSpace: "nowrap" }}></i>{" "}
            Tilgangsgruppe
          </Link>

          <Link to="/userDashboard/accessusers">
            <i className="i-Find-User"></i> Brukere
          </Link>

          <Link to="/Building/buildingview">
            <i className="i-Map" style={{ whiteSpace: "nowrap" }}></i> Bygning plans
          </Link>
        </div>
      </Col>
    </Row>
  );
}
