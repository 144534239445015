import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyList: [],
  selectedCompany: null,
  selectedCompanyId: null,
};

const companySlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    companyListAction: (state, action) => {
      state.companyList = [...state.companyList, ...action.payload.companyList];
    },
    companySelectAction: (state, action) => {
      state.selectedCompany = action.payload.selectedCompany;
      state.selectedCompanyId = action.payload.selectedCompanyId;
      localStorage.setItem('CompanyId',action.payload.selectedCompanyId);
    },
  },
});

export const {
  companyListAction,
  companySelectAction,
} = companySlice.actions;

export default companySlice.reducer;
