import React from "react";
import { createRoot } from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import { pca } from "./authConfig";
import { Provider } from "react-redux";
import { store } from "./app/redux/Store";
// ROOT APP
import App from "./app/App";

import "./styles/app/app.scss";
// import "./index.css";
//import "./index.scss";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);
