import { apiSliceUserManager } from "../api/apiSlice";
import { updateUserDetailAction } from "./userManagerSlice";

export const userManagerApi = apiSliceUserManager.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => "user/getuser",
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          if (result.data) {
            dispatch(updateUserDetailAction(result.data));
          }
        } catch (err) {}
      },
    }),
  }),
});

export const { useLazyGetUserInfoQuery } = userManagerApi;
