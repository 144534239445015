import { Suspense, Fragment, lazy } from "react";
import { useSelector } from "react-redux";
import Customizer from "./shared/Customizer";
import { Outlet} from "react-router-dom";

const layouts = {
  KeyFreeDashboardLayout: lazy(() => import("./DashboardLayout/DashboardLayout")),
};

export default function KeyFreeLayout({ children }) {
  const { settings } = useSelector((state) => state.layout);

  // SELECTED LAYOUT BASED ON ACTIVE SETTING
  const Layout = layouts[settings.activeLayout];

  return (
    <Suspense>
      <Fragment>
        <Layout><Outlet /></Layout>
        {settings.customizer.show ? <Customizer /> : null}
      </Fragment>
    </Suspense>
  );
}
