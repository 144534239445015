import { PublicClientApplication, BrowserCacheLocation, LogLevel } from "@azure/msal-browser";


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


const b2cPolicies = {
  names: {
      signUpSignIn: 'B2C_1_keyfree_signInsignUp',
      //resetPassword: 'B2C_1_Vendor_ResetPassword'
  },
  authorities: {
      signUpSignIn: {
          authority: 'https://StyretB2CTenant.b2clogin.com/StyretB2CTenant.onmicrosoft.com/B2C_1_keyfree_signInsignUp'
      },
    //   resetPassword: {
    //       authority: 'https://StyretB2CTenant.b2clogin.com/StyretB2CTenant.onmicrosoft.com/B2C_1_Vendor_ResetPassword'
    //   }
  },
  authorityDomain: 'StyretB2CTenant.b2clogin.com'
};

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
},
cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
},
system: {
    loggerOptions: {
        loggerCallback(logLevel, message) {
            //console.log(message);
        },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
    }
}
};

const pca = await PublicClientApplication.createPublicClientApplication(configuration);
export {pca}