import dashboardLayoutSettings from "./DashboardLayout/settings";

export const layoutSettings = {
  dashboardLayoutSettings,

  dir: "ltr", // ltr, rtl
  activeLayout: "KeyFreeDashboardLayout", // layout1, layout2

  footer: { show: true },
  customizer: { show: false, open: false }
};
