import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, getTokenRedirect } from "app/auth/getToken";
import header from "app/layouts/DashboardLayout/header";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    return getToken()
      .then((response) => {
        let token = response.accessToken;
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("subscription-key", process.env.REACT_APP_SUBSCRIPTION_KEY);
        headers.set("CompanyId", localStorage.getItem('CompanyId'));
        headers.set("parentcompanyid", 1549);
        //headers.set("companyEmail", sessionStorage.getItem("companyEmail"));
        return headers;
      })
      .catch((error) => {
        return getTokenRedirect()
          .then((response) => {
            let token = response.accessToken;
            headers.set("Authorization", `Bearer ${token}`);
            headers.set(
              "subscription-key",
              process.env.REACT_APP_SUBSCRIPTION_KEY
            );
            headers.set("CompanyId", localStorage.getItem('CompanyId'));
            //headers.set("companyEmail", sessionStorage.getItem("companyEmail"));
            return headers;
          })
          .catch((err) => {
            console.log(err);
          });
      });
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
      //api.dispatch(userLoggedOut());
      //localStorage.clear();
    }
    return result;
  },
  // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:3000", axios }),
  tagTypes: [],
  endpoints: (builder) => ({}),
});

const baseQueryAMSManager = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AMS_API_BASE,
  prepareHeaders: async (headers, { getState, endPoint }) => {
    return getToken()
      .then((response) => {
        let token = response.accessToken;
        headers.set("Authorization", `Bearer ${token}`);
        headers.set(
          "subscription-key",
          process.env.REACT_APP_AMS_SUBSCRIPTION_KEY
        );
        return headers;
      })
      .catch((error) => {
        return getTokenRedirect()
          .then((response) => {
            let token = response.accessToken;
            headers.set("Authorization", `Bearer ${token}`);
            headers.set(
              "subscription-key",
              process.env.REACT_APP_AMS_SUBSCRIPTION_KEY
            );
            return headers;
          })
          .catch((err) => {
            console.log(err);
          });
      });
  },
});

export const apiSliceAMSManager = createApi({
  reducerPath: "amsAPI",
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQueryAMSManager(args, api, extraOptions);

    if (result?.error?.status === 401) {
    }
    return result;
  },
  tagTypes: [],
  endpoints: () => ({}),
});

const baseQueryUserManager = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_USERMANAGER_API_BASE,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    return getToken()
      .then((response) => {
        let token = response.accessToken;
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      })
      .catch((error) => {
        return getTokenRedirect()
          .then((response) => {
            let token = response.accessToken;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
          })
          .catch((err) => {
            console.log(err);
          });
      });
  },
});

export const apiSliceUserManager = createApi({
  reducerPath: "userManagerApi",
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQueryUserManager(args, api, extraOptions);

    if (result?.error?.status === 401) {
      //api.dispatch(userLoggedOut());
      //localStorage.clear();
    }
    return result;
  },
  // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:3000", axios }),
  tagTypes: [],
  endpoints: (builder) => ({}),
});
