import { Provider } from "react-redux";
import React, { useEffect } from "react";
import { useMsalAuthentication, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useLazyGetUserInfoQuery } from "app/redux/UserManager/userManagerApi";

// ALL PAGE ROUTES
import routes from "./routes";
// REDUX STORE
import { store } from "./redux/Store";
// FAKE DB
import "../fake-db";

function App() {
  useMsalAuthentication(InteractionType.Redirect);
  const isAuthenticated = useIsAuthenticated();
  const [getuserInfo] = useLazyGetUserInfoQuery();

  useEffect(() => {
    if (isAuthenticated) {
      getuserInfo();
    }
  }, [isAuthenticated]);

  return (
    <Provider store={store}>
      <RouterProvider router={routes} />
      <ToastContainer />
    </Provider>
  );
}

export default App;
