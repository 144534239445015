import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buildingFacilityList: [],
  companyAccessPointList: [],
  companyAccessGroupList: []
};

const facilitySlice = createSlice({
  name: "facilitySlice",
  initialState,
  reducers: {
    buildingFacilityListAction: (state, action) => {
      state.buildingFacilityList = [
        ...state.buildingFacilityList,
        ...action.payload.buildingFacilityList,
      ];
    },
    companyAccessPointAction: (state, action) => {
      state.companyAccessPointList = action.payload.companyAccessPointList;
    },
    companyAccessGroupAction: (state, action) => {
      state.companyAccessGroupList = action.payload.companyAccessGroupList;
    },
    companyAccessGroupDetailAction: (state, action) => {
      state.companyAccessGroupDetail = action.payload.companyAccessGroupDetail;
    }
  },
});

export const {
  buildingFacilityListAction,
  companyAccessPointAction,
  companyAccessGroupAction,
  companyAccessGroupDetailAction
} = facilitySlice.actions;

export default facilitySlice.reducer;
