import { lazy } from "react";
// import { authRoles } from "app/auth/authRoles";

const UserDashboard = lazy(() => import("./userDashboard/UserDashboard"));
const AccessPoints = lazy(() => import("./userDashboard/AccessPoints"));
const AccessGroups = lazy(() => import("./userDashboard/AccessGroup"));
const AccessUsers = lazy(() => import("./userDashboard/AccessUsers"));
const dashboardRoutes = [
  {
    path: "/",
    element: <UserDashboard />,
    // auth: authRoles.admin,
  },
  {
    path: "/userDashboard/accesspoint",
    element: <AccessPoints />,
    // auth: authRoles.admin,
  },
  {
    path: "/userDashboard/accessgroups",
    element: <AccessGroups />,
    // auth: authRoles.admin,
  },
  {
    path: "/userDashboard/accessusers",
    element: <AccessUsers />,
    // auth: authRoles.admin,
  },
];

export default dashboardRoutes;
