import { pca } from "authConfig";
async function getToken() {

    return pca.handleRedirectPromise()
    .then((response) => {
      const account = pca.getAllAccounts()[0];
      let accessTokenRequest = {
        scopes: ['openid', 'profile', process.env.REACT_APP_API_SCOPE],
        account: account,
      };
      return pca.acquireTokenSilent(accessTokenRequest)
    }).catch((err) => {
      console.log(err);
    })
}

async function getTokenRedirect() {
  return pca.handleRedirectPromise()
  .then((response) => {
    let accessTokenRequest = {
      scopes: ['openid', 'profile', process.env.REACT_APP_API_SCOPE],
    };
    return pca.acquireTokenRedirect(accessTokenRequest);
  }).catch((err) => {
    console.log(err);
  })
}

export {getToken, getTokenRedirect}