import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessUserList: [],
};

const accessUserSlice = createSlice({
  name: "accessUserSlice",
  initialState,
  reducers: {
    accessUserAction: (state, action) => {
      state.accessUserList = action.payload.accessUserList;
    },
  },
});

export const { accessUserAction } = accessUserSlice.actions;

export default accessUserSlice.reducer;
