import { lazy } from "react";

const BuildingView = lazy(() => import("./BuildingView"));

const buildingRoutes = [
    {
      path: "/Building/buildingview",
      element: <BuildingView />,
      // auth: authRoles.admin,
    },
  ];
  
  export default buildingRoutes;